import React from "react";
import { cn } from "../lib/helpers";
import PortableText from "./portableText";

import * as styles from "../styles/components/faq-item.module.css";

const FaqItem = (props) => {

  const {
    _key,
    question,
    _rawAnswer
  } = props;

  return (
    <div key={_key} className={styles.root}>
      {question && (
        <div className={cn(styles.question, "containerContentLg")}>
          <p className="normalp">{question}</p>
        </div>
      )}
      {_rawAnswer && (
        <div className={cn(styles.answer, "containerContent")}>
          <PortableText blocks={_rawAnswer} />
        </div>
      )}
    </div>
  );
}

export default FaqItem;
