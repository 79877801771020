import React from "react";
import Container from "./container";
import FaqItem from "./faq-item";

import * as styles from "../styles/components/faq-list.module.css";

function FaqList({ items }) {
  return (
    <div className={styles.root}>
      <Container>
        {items.map((item) => {
          return (
            <FaqItem {...item} />
          );
        })}
      </Container>
    </div>
  );
}

export default FaqList;
