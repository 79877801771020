import React from "react";
import { graphql } from "gatsby";
import GraphQLErrorList from "../components/graphql-error-list";
import Seo from "../components/seo";
import Layout from "../containers/layout";

import PageHeader from "../components/page-header";
import FaqList from "../components/faq-list";

export const query = graphql`
  query FaqPageQuery {
    faq: sanityFaq {
      title
      faqItems {
        _key
        question
        _rawAnswer
      }
      description
    }
  }
`;

const FaqPage = (props) => {
  const { data, errors } = props;

  if (errors) {
    return (
      <Layout>
        <GraphQLErrorList errors={errors} />
      </Layout>
    );
  }

  const faq = (data || {}).faq;

  return (
    <Layout>
      <Seo
        title={faq.title}
        description={faq.description}
      />

      {faq.title && <PageHeader title={faq.title} />}

      {faq.faqItems && <FaqList items={faq.faqItems} />}
        
    </Layout>
  );
};

export default FaqPage;
