import Container from "./container";
import React from "react";

import * as styles from "../styles/components/page-header.module.css";

const PageHeader = (props) => {
  const {
    title
  } = props;

  return (
    <div className={styles.root}>
      <Container>
        {title && <h1 className={styles.heading}>{title || "Untitled"}</h1>}
      </Container>
    </div>
  );
}

export default PageHeader;
